body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-latin-ext_latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/roboto-v30-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/roboto-v30-latin-ext_latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/roboto-v30-latin-ext_latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/roboto-v30-latin-ext_latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/roboto-v30-latin-ext_latin-regular.svg#Roboto') format('svg');
  /* Legacy iOS */
}